import React, { useContext } from 'react';
import './footer.scss';
import { Box } from '@wds/box';
import getFooterLinks from './getFooterLinks';
import { StaticContext } from '../../StaticContext';
import useFides from '../../../helpers/hooks/useFides';

const Footer = () => {
  const {
    environment: { languageCode },
    GDPR: { fidesBannerEnabled, onetrustBannerEnabled } = {},
  } = useContext(StaticContext);

  const Fides = useFides();

  return (
    <Box element="footer" display="flex" className="sm-captive-footer">
      <Box p={2} m={3}>
        {getFooterLinks(languageCode).map(linkData => (
          <a
            key={linkData.href}
            className="sm-captive-footer__link"
            href={linkData.href}
            rel="noopener noreferrer"
          >
            {linkData.text}
          </a>
        ))}
        {/* Onetrust pipes in the text content here */}
        {!fidesBannerEnabled && !!onetrustBannerEnabled && (
          /* eslint-disable-next-line jsx-a11y/anchor-has-content */
          <a
            href="#cookiePreferences"
            className="ot-sdk-show-settings"
            data-testid="CaptiveFooterLinks__cookiePreferences"
          />
        )}
        {/* Fides pipes in the text content here */}
        {!!fidesBannerEnabled && (
          <a
            id="fides-modal-link"
            href="#cookiePreferences"
            className="fides-modal-link-shown"
            data-testid="CaptiveFooterLinks__cookiePreferences_fides"
            onClick={() => Fides?.showModal()}
          >
            {Fides?.getModalLinkLabel()}
          </a>
        )}
      </Box>
    </Box>
  );
};
export default Footer;
