import React, { useContext } from 'react';
import { getAuthFooterLinks } from './helpers';
import { InlineList, InlineListItem } from '../InlineList';
import { SMFooterContext } from '../SMFooterContext';
import CopyRight from '../CopyRight';
import useFides from '../../../helpers/hooks/useFides';

export interface AuthedFooterLinksProps {
  domain: string;
  lang: string;
}

const AuthedFooterLinks = ({
  domain = 'surveymonkey',
  lang = 'en',
}: AuthedFooterLinksProps) => {
  const { onetrustBannerEnabled, fidesBannerEnabled } =
    useContext(SMFooterContext);
  const links = getAuthFooterLinks(domain, lang);
  const Fides = useFides();

  return (
    <InlineList noMargin>
      {links.map(item => (
        <InlineListItem key={item.title} href={item.url}>
          {item.title}
        </InlineListItem>
      ))}
      <InlineListItem>
        <CopyRight />
      </InlineListItem>
      {/* Onetrust pipes in the text content here */}
      {!fidesBannerEnabled && !!onetrustBannerEnabled && (
        <InlineListItem
          href="#cookiePreferences"
          className="ot-sdk-show-settings"
          data-testid="AuthedFooterLinks__cookiePreferences"
          lastItem
        />
      )}
      {/* Fides pipes in the text content here */}
      {!!fidesBannerEnabled && (
        <InlineListItem
          href="#cookiePreferences"
          className="fides-modal-link-shown"
          data-testid="AuthedFooterLinks__cookiePreferences_fides"
          lastItem
          {...{
            id: 'fides-modal-link',
            onClick: () => {
              Fides?.showModal();
            },
          }}
        >
          {Fides?.getModalLinkLabel()}
        </InlineListItem>
      )}
    </InlineList>
  );
};

export default AuthedFooterLinks;
