// Helpers for localizing PackageTypes
import { defineMessages, t } from '@sm/intl';
import { PackageType } from '@sm/utils';

const COPY = defineMessages({
  BASIC: {
    id: 'packageHelpers.BasicPackage',
    defaultMessage: 'Basic',
    description:
      '[Type: Label][Vis: med] - The name for the Basic account plan.',
  },
  PRO: {
    id: 'packageHelpers.ProPackage',
    defaultMessage: 'Pro',
    description: '[Type: Label][Vis: med] - The name for the Pro account plan.',
  },
  UNLIMITED: {
    id: 'packageHelpers.UnlimitedPackage',
    defaultMessage: 'Unlimited',
    description:
      '[Type: Label][Vis: med] - The name for the Unlimited account plan.',
  },
  SELECT_MONTHLY: {
    id: 'packageHelpers.Select MonthlyPackage',
    defaultMessage: 'Select Monthly',
    description:
      '[Type: Label][Vis: med] - The name for the Select Monthly account plan.',
  },
  SELECT_YEARLY: {
    id: 'packageHelpers.Select AnnualPackage',
    defaultMessage: 'Select Annual',
    description:
      '[Type: Label][Vis: med] - The name for the Select Annual account plan.',
  },
  GOLD: {
    id: 'packageHelpers.GoldPackage',
    defaultMessage: 'Gold',
    description:
      '[Type: Label][Vis: med] - The name for the Gold account plan.',
  },
  PLATINUM: {
    id: 'packageHelpers.PlatinumPackage',
    defaultMessage: 'Platinum',
    description:
      '[Type: Label][Vis: med] - The name for the Platinum account plan.',
  },
  TEMP_PRO: {
    id: 'packageHelpers.TempProPackage',
    defaultMessage: 'Temporary Pro',
    description:
      '[Type: Label][Vis: med] - The name for the Temporary Pro account plan.',
  },
  PRO_COMP: {
    id: 'packageHelpers.CompProPackage',
    defaultMessage: 'Complementary Pro',
    description:
      '[Type: Label][Vis: med] - The name for the Complementary Pro account plan.',
  },
  ZOOM_PREMIUM: {
    id: 'packageHelpers.ZoomPremiumPackage',
    defaultMessage: 'Zoomerang Premium',
    description:
      '[Type: Label][Vis: med] - The name for the Zoomerang Premium account plan.',
  },
  ZOOM_PRO: {
    id: 'packageHelpers.ZoomProPackage',
    defaultMessage: 'Zoomerang Pro',
    description:
      '[Type: Label][Vis: med] - The name for the Zoomerang Pro account plan.',
  },
  ENTERPRISE_GOLD: {
    id: 'packageHelpers.EnterpriseGoldPackage',
    defaultMessage: 'Enterprise Gold',
    description:
      '[Type: Label][Vis: med] - The name for the Enterprise Gold account plan.',
  },
  ENTERPRISE_PLATINUM: {
    id: 'packageHelpers.EnterprisePackage',
    defaultMessage: 'Enterprise',
    description:
      '[Type: Label][Vis: med] - The name for the Enterprise account plan.',
  },
  ENTERPRISE_PRO: {
    id: 'packageHelpers.EnterpriseProPackage',
    defaultMessage: 'Enterprise Pro',
    description:
      '[Type: Label][Vis: med] - The name for the Enterprise Pro account plan.',
  },
  ENTERPRISE_ULTIMATE: {
    id: 'packageHelpers.EnterpriseUltimatePackage',
    defaultMessage: 'Enterprise Ultimate',
    description:
      '[Type: Label][Vis: med] - The name for the Enterprise Ultimate account plan.',
  },
  ENTERPRISE: {
    id: 'packageHelpers.EnterprisePackage',
    defaultMessage: 'Enterprise',
    description:
      '[Type: Label][Vis: med] - The name for the Enterprise account plan.',
  },
  STANDARD_MONTHLY: {
    id: 'packageHelpers.StandardMonthlyPackage',
    defaultMessage: 'Standard Monthly',
    description:
      '[Type: Label][Vis: med] - The name for the Standard Monthly account plan.',
  },
  STANDARD_ANNUAL: {
    id: 'packageHelpers.StandardAnnualPackage',
    defaultMessage: 'Standard Annual',
    description:
      '[Type: Label][Vis: med] - The name for the Standard Annual account plan.',
  },
  ADVANTAGE_MONTHLY: {
    id: 'packageHelpers.AdvantageMonthlyPackage',
    defaultMessage: 'Advantage Monthly',
    description:
      '[Type: Label][Vis: med] - The name for the Advantage Monthly account plan.',
  },
  ADVANTAGE_ANNUAL: {
    id: 'packageHelpers.AdvantageAnnualPackage',
    defaultMessage: 'Advantage Annual',
    description:
      '[Type: Label][Vis: med] - The name for the Advantage Annual account plan.',
  },
  PREMIER_MONTHLY: {
    id: 'packageHelpers.PremierMonthlyPackage',
    defaultMessage: 'Premier Monthly',
    description:
      '[Type: Label][Vis: med] - The name for the Premier Monthly account plan.',
  },
  PREMIER_ANNUAL: {
    id: 'packageHelpers.PremierAnnualPackage',
    defaultMessage: 'Premier Annual',
    description:
      '[Type: Label][Vis: med] - The name for the Premier Annual account plan.',
  },
  ANALYZE_PACKAGE: {
    id: 'packageHelpers.FlexPackagePackage',
    defaultMessage: 'Flex Package',
    description:
      '[Type: Label][Vis: med] - The name for the Flex Package account plan.',
  },
  TEAM_ADVANTAGE_ANNUAL: {
    id: 'packageHelpers.TeamAdvantageAnnualPackage',
    defaultMessage: 'Team Advantage',
    description:
      '[Type: Label][Vis: med] - The name for the Team Advantage Annual account plan.',
  },
  TEAM_PREMIER_ANNUAL: {
    id: 'packageHelpers.TeamPremierAnnualPackage',
    defaultMessage: 'Team Premier',
    description:
      '[Type: Label][Vis: med] - The name for the Team Premier Annual account plan.',
  },
  ENTERPRISE_CONTRIBUTOR_SEAT: {
    id: 'packageHelpers.EnterpriseContributorSeatPackage',
    defaultMessage: 'Enterprise Contributor Seat',
    description:
      '[Type: Label][Vis: med] - The name for the Enterprise Contributor Seat account plan.',
  },
  TEAM_ADVANTAGE_CONTRIBUTOR_SEAT: {
    id: 'packageHelpers.TeamAdvantageContributorSeatPackage',
    defaultMessage: 'Team Advantage Contributor Seat',
    description:
      '[Type: Label][Vis: med] - The name for the Team Advantage Contributor Seat account plan.',
  },
  TEAM_PREMIER_CONTRIBUTOR_SEAT: {
    id: 'packageHelpers.TeamPremierContributorSeatPackage',
    defaultMessage: 'Team Premier Contributor Seat',
    description:
      '[Type: Label][Vis: med] - The name for the Team Premier Contributor Seat account plan.',
  },
});

/**
 * Returns the translated display name of a package.
 *
 * @param packageID
 * @returns The localized name
 */
export default function getPackageName(packageID: PackageType): string {
  const packageTypeToName: Record<PackageType, string> = {
    [PackageType.BASIC]: t(COPY.BASIC),
    [PackageType.PRO]: t(COPY.PRO),
    [PackageType.UNLIMITED]: t(COPY.UNLIMITED),
    [PackageType.SELECT_MONTHLY]: t(COPY.SELECT_MONTHLY),
    [PackageType.SELECT_YEARLY]: t(COPY.SELECT_YEARLY),
    [PackageType.GOLD]: t(COPY.GOLD),
    [PackageType.PLATINUM]: t(COPY.PLATINUM),
    [PackageType.TEMP_PRO]: t(COPY.TEMP_PRO),
    [PackageType.PRO_COMP]: t(COPY.PRO_COMP),
    [PackageType.ZOOM_PREMIUM]: t(COPY.ZOOM_PREMIUM),
    [PackageType.ZOOM_PRO]: t(COPY.ZOOM_PRO),
    [PackageType.ENT_GOLD]: t(COPY.ENTERPRISE_GOLD),
    [PackageType.ENTERPRISE_PLATINUM]: t(COPY.ENTERPRISE),
    [PackageType.ENTERPRISE]: t(COPY.ENTERPRISE),
    [PackageType.ENTERPRISE_CASUAL_USER]: t(COPY.ENTERPRISE), // Billing claims display name of enterprise casual is also "Enterprise"
    [PackageType.STANDARD_MONTHLY]: t(COPY.STANDARD_MONTHLY),
    [PackageType.STANDARD_ANNUAL]: t(COPY.STANDARD_ANNUAL),
    [PackageType.ADVANTAGE_MONTHLY]: t(COPY.ADVANTAGE_MONTHLY),
    [PackageType.ADVANTAGE_ANNUAL]: t(COPY.ADVANTAGE_ANNUAL),
    [PackageType.PREMIER_MONTHLY]: t(COPY.PREMIER_MONTHLY),
    [PackageType.PREMIER_ANNUAL]: t(COPY.PREMIER_ANNUAL),
    [PackageType.ANALYZE_PACKAGE]: t(COPY.ANALYZE_PACKAGE),
    [PackageType.TEAM_ADVANTAGE_ANNUAL]: t(COPY.TEAM_ADVANTAGE_ANNUAL),
    [PackageType.TEAM_PREMIER_ANNUAL]: t(COPY.TEAM_PREMIER_ANNUAL),
    [PackageType.ENTERPRISE_CONTRIBUTOR_SEAT]: t(
      COPY.ENTERPRISE_CONTRIBUTOR_SEAT
    ),
    [PackageType.TEAM_ADVANTAGE_CONTRIBUTOR_SEAT]: t(
      COPY.TEAM_ADVANTAGE_CONTRIBUTOR_SEAT
    ),
    [PackageType.TEAM_PREMIER_CONTRIBUTOR_SEAT]: t(
      COPY.TEAM_PREMIER_CONTRIBUTOR_SEAT
    ),
    [PackageType.ENTERPRISE_PRO]: t(COPY.ENTERPRISE_PRO),
    [PackageType.ENTERPRISE_ULTIMATE]: t(COPY.ENTERPRISE_ULTIMATE),
  };
  return packageTypeToName[packageID];
}
