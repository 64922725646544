import React, { useContext } from 'react';
import { getAnonCategories } from './helpers';
import { InlineList, InlineListItem } from '../InlineList';
import { SMFooterContext } from '../SMFooterContext';
import useFides from '../../../helpers/hooks/useFides';

export interface FooterLink {
  title?: string;
  url?: string;
  anchorAttributes?: {
    rel: string;
  };
  isTitle?: boolean;
  lastItem?: boolean;
}

const AnonFooterLinks = () => {
  const {
    domain,
    lang,
    country,
    variant,
    onetrustBannerEnabled,
    fidesBannerEnabled,
  } = useContext(SMFooterContext);

  const categories = getAnonCategories(domain, lang, country, variant);
  const margin = variant === 'slim';
  const Fides = useFides();

  return (
    <>
      {categories.map((category, i) => (
        <InlineList key={category[0].title} noMargin={margin}>
          {category.map((item: FooterLink) => (
            <InlineListItem
              href={item.url}
              anchorAttributes={item.anchorAttributes}
              key={item.title}
              isTitle={item.isTitle}
              lastItem={item.lastItem}
            >
              {item.title}
            </InlineListItem>
          ))}
          {/* Onetrust pipes in the text content here */}
          {i === 2 && !fidesBannerEnabled && !!onetrustBannerEnabled && (
            <InlineListItem
              href="#cookiePreferences"
              className="ot-sdk-show-settings"
              data-testid="AnonFooterLinks__cookiePreferences"
              lastItem
            />
          )}
          {/* Fides pipes in the text content here */}
          {i === 2 && !!fidesBannerEnabled && (
            <InlineListItem
              href="#cookiePreferences"
              className="fides-modal-link-shown"
              data-testid="AnonFooterLinks__cookiePreferences_fides"
              lastItem
              {...{
                id: 'fides-modal-link',
                onClick: () => {
                  Fides?.showModal();
                },
              }}
            >
              {Fides?.getModalLinkLabel()}
            </InlineListItem>
          )}
        </InlineList>
      ))}
    </>
  );
};

export default AnonFooterLinks;
